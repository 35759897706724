
.image-modal-wrapper {
    height: 100%;
    position: relative;
    width: auto;
    display: inline-block;

    .close-btn {
        z-index: 2;
        position: absolute;
        margin-top: -23px;
    }

    img.photo-preview {
        max-width: 100%;
        max-height: 100%;
        border-radius: 5px;
    }
}
