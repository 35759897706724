
.video-modal-wrapper {
    height: 100%;
    position: relative;
    width: auto;
    display: inline-block;

    .close-btn {
        position: absolute;
        margin-top: -23px;
    }

    .play-btn {
        color: white;
        font-size: 68px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 1;
        &:hover {
            color: #eee;
        }
    }

    video#video-preview {
        object-fit: contain;
        width: 100%;
        height: 100%;
        background: black;

        &.center {
            width: 100%;
            margin: 0;
        }
    }

    &.not-supported {
        display: flex;
        align-items: center;
        min-height: 450px;

        & > div {
            word-break: break-word;
        }

        p {
            line-height: 2em;
        }

        a {
            font-family: Avenir;
            font-size: 13px;
            text-decoration: underline;
            color: #141414;
        }
    }
}
