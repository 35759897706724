.gallery-container {
    .gallery-header {
        position: relative;
        vertical-align: middle;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .menu-icon-wrapper {
            margin-top: 5px;
            margin-right: 15px;
        }
    }
    .gallery-placeholder {
        border-radius: 14px;
        border: solid 1px rgba(80, 81, 82, 0.25);
        background-color: #ffffff;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        .empty {
            padding: 40px;
            text-align: center;
        }
        .add-button {
            display: inline-block;
            padding-left: 10px;
        }
    }
}