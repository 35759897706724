
.z-100 {
    z-index: 100 !important;
}
.gallery-modal {
    width: auto !important;
    border: none;
    padding: 17px;
    padding-top: 3rem;

    div.media-preview {
        height: 100%;
        position: relative;
    }

    .visit-btn {
        color: white;
        font-size: 34px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 1;
        padding: 5px 60px;
        border-radius: 30px;
        border: 2px solid white;

        &:hover {
            background-color: #ffffff4d;
        }
    }
}
