
@import 'scss/components/_gallery';

.thumb .thumb-img {
    min-width: 177px;
    height: 177px;
    border-radius: 0;
    background-color: black;
}

.gallery-container {
    margin: 0 -20px;
    margin-top: 20px;

    .gallery-placeholder {
        border: none;
        padding: 0;
    }
}
